<template>
  <div class="draggable-item" :class="{'box': addBox}">
    <template v-if="item.data.url">
      <img @mousedown.prevent :src="item.data.url" alt="Kuva" />
    </template>
    <template v-else>
      {{item.data}}
    </template>
    
    <!-- {{inputText}} -->
    <!-- <br>Nimeä osa:<br> -->
    <!-- <template v-if="(typeof inputText) !== 'undefined'">
      <input type="text" :value="inputText" @input="emitInputText($event)">
    </template> -->
  </div>
</template>

<script>

export default ({
  name: 'DraggableItem',
  props: ['item', 'inputText', 'addBox'],
  setup(props, { emit }) {
    function emitInputText(e) {
      emit('update:inputText', e.target.value)
    }

    return {
      emitInputText
    }
  }
})
</script>

<style lang="scss" scoped>
  .draggable-item {
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .box {
    background-color: var(--theme-color-fourth);
  }
</style>
